import React from "react";

import styles from './Hivatasunk.module.css';

const Hivatasunk = (props) => {
    return (
        <div className={styles['container']} style={{ backgroundImage: 'url(main.jpg)' }}>
            <div className={styles['text-wrapper']}>
            <h1 className={styles['h1']}>Kiskedvencek hamvasztása</h1>
            <h2 className={styles['h3']}>"Ugye ők is megérdemlik a végső tiszteletet?"</h2>
                <p className={styles['p']}>
                    2006 óta segíteni szándékozunk azoknak a gazdiknak akik szeretnék megadni a velük éveken át együtt élő kiskedvencüknek a végső tiszteletet.A kisállat, aki szinte családtagként él közöttünk biztosan megérdemli azt, hogy halála után is megfelelő helyen nyugodjon békében.
                </p>
                <p>
                    Ebben szeretnénk megoldást nyújtani minden szerető gazdi számára a kisállat hamvasztási szolgáltatásunkkal.
                </p>
            </div>
        </div>
    )
};

export default Hivatasunk;
