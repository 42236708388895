import React, { useContext, useEffect, useState } from "react";

import Context from '../Context';
import Cikkek from './Cikkek';
import Hivatasunk from "./Hivatasunk";
import Szolgaltatasaink from "./Szolgaltatasaink";
import Urnak from './Urnak';

const screens = [
    <Hivatasunk/>,
    <Szolgaltatasaink/>,
    <Urnak/>,
    <Cikkek/>
];

const screenMapper = {
    '/': 0,
    '/hivatasunk': 0,
    '/szolgaltatasaink': 1,
    '/urnak': 2,
    '/cikkek': 3
};

const Main = (props) => {
    const { selectedMenu } = useContext(Context);
    const [screen, setScreen] = useState(screens[0]);

    useEffect(() => {
        let url = window.location.pathname;
        setScreen(screens[screenMapper[url]]);

        window.onpopstate = function(e) {
            let url = window.location.pathname;
            setScreen(screens[screenMapper[url]]);
        };

    }, [selectedMenu]);

    return (
        screen
    );
};

export default Main;
