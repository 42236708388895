import React, { useEffect, useState } from "react";

import Phone from './phone.png';

import './Footer.css';

const Footer = (props) => {
    const [renderMap, setRenderMap] = useState(false);

    useEffect(() => {
        window.onload = function() {
            setRenderMap(true);
        }
    }, []);

    return (
        <div className='footer-container'>
            <div className='footer-contact'>
                <h3>Kapcsolat</h3>
                <div className='footer-phone-div'>
                    <img alt='phone' className={'footer-phone-icon'} src={Phone}/>
                    <a href='tel:+36304064377' className='footer-phone'>Pocsai Lajos: +36 30/406-4377</a>
                </div>
                <div className='footer-phone-div'>
                    <img alt='phone' className={'footer-phone-icon'} src={Phone}/>
                    <a href='tel:+36707023767' className='footer-phone'>Pocsai Lajosné: +36 70/702-3767</a>
                </div>
            </div>
            <div className='footer-address'>
                <h3 className='footer-address-title'>Cím</h3>
                <p className='footer-address-text'>MKH Szolgáltató Bt.</p>
                <p className='footer-address-text'>6791 Szeged, Tapodi utca 10.</p>
                {
                    renderMap &&
                        <iframe title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2757.32682247702!2d20.06895031558503!3d46.28348097911909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4744876778a488f1%3A0x2c74eaae3eb266b4!2sSzeged%2C%20Tapodi%20u.%2010%2C%206791!5e0!3m2!1shu!2shu!4v1636397084619!5m2!1shu!2shu" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
                }
            </div>
        </div>
    )
};

export default Footer;
