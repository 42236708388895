import React, { useState } from 'react';
import Context from './Context';

const screenMapper = {
    '/' : 0,
    '/hivatasunk': 0,
    '/szolgaltatasaink': 1,
    '/urnak': 2,
    '/cikkek': 3
};

const GlobalStore = (props) => {
    const [selectedMenu, setSelectedMenu] = useState(screenMapper[window.location.pathname]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    return (
        <Context.Provider
            value={{
                selectedMenu,
                setSelectedMenu,
                showModal,
                setShowModal,
                selectedImage,
                setSelectedImage
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default GlobalStore;
