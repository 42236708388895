import React, { useContext } from "react";
import Context from "../Context";

import styles from './Urnak.module.css';

const Urnak = (props) => {
    const { setShowModal, setSelectedImage } = useContext(Context);

    const urns = [
        {
            image: '/urn_1.jpg',
            preview: '/urn_1_preview.jpg'
        },
        {
            image: '/urn_2.jpg',
            preview: '/urn_2_preview.jpg'
        },
        {
            image: '/urn_3.jpg',
            preview: '/urn_3_preview.jpg'
        },
        {
            image: '/urn_4.jpg',
            preview: '/urn_4_preview.jpg'
        },
        {
            image: '/urn_5.jpg',
            preview: '/urn_5_preview.jpg'
        },

    ];

    const urnClick = (path) => {
        setSelectedImage(path);
        setShowModal(true);
    };

    return (
        <div className={styles['container']} style={{ backgroundImage: 'url(main.jpg)' }}>
            <h1 className={styles['h1']}>Urnáink</h1>
            <div className={styles['wrapper']}>
                {
                    urns.map((urn) => {
                        return (
                            <div style={{backgroundImage: `url(${urn.preview})`}} onClick={() => { urnClick(urn.image) }} className={styles['urn']}>
                                <div className={styles['overlay']}/>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Urnak;
