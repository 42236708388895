import React from "react";

import styles from './Szolgaltatasaink.module.css';

const Szolgaltatasaink = (props) => {
    return (
        <div className={styles['container']} style={{ backgroundImage: 'url(main.jpg)' }}>
            <div className={styles['text-wrapper']}>
                <h3 className={styles['title']}>Szolgáltatásunk</h3>
                <p className={styles['p']}>A tevékenységet valamennyi ellenörző szervezet által jóváhagyott engedéllyel végezzük. A hamvasztó Európa legbiztonságosabb és legmodernebb berendezése, maximált kapacitása 80 kg.</p>
                <p className={styles['p']}>A kisállat hamvasztása igény szerint a hét bármely napján a jelzést követően 12 órán belül elvégzésre kerül. Minden hamvasztást megelőz egy kegyeleti szertartás, és ezt követően a hamvak a kiválasztott urnába kerülnek.</p>
                <p className={styles['p']}>A kegyeleti eljárás 2-3 órát vesz igénybe, ahol a gazdi végig jelen lehet, és megvárhatja a hamvak urnába helyezését is.</p>
            </div>
        </div>
    )
};

export default Szolgaltatasaink;
