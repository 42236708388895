import React from "react";

import styles from './Cikkek.module.css';

import Kuty from './Kuty.webm';

const Cikkek = (props) => {
    return (
        <div className={styles['container']} style={{ backgroundImage: 'url(main.jpg)' }}>
            <h1 className={styles['h1']}>RTL Klub híradó</h1>
            <div className={styles['wrapper']}>
                <video className={styles['video']} width={800} height={600} controls>
                    <source src={Kuty} type={"video/webm"}/>
                </video>
            </div>
        </div>
    );
};

export default Cikkek;
