import { Fragment } from 'react';
import styles from './App.module.css';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import Main from './Main/Main';
import Modal from './Modal';

function App() {
    return (
        <Fragment>
            <div className={styles['container']}>
                <header className={styles['header']}>
                    <Header/>
                </header>
                <main className={styles['main']}>
                    <Main/>
                </main>
                <footer>
                    <Footer/>
                </footer>
            </div>
            <Modal/>
        </Fragment>
    );
}

export default App;
