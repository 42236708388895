import React, { useContext } from "react";

import styles from './Modal.module.css';

import Context from "./Context";

const Modal = (props) => {
    const { selectedImage, setShowModal, showModal } = useContext(Context);

    return (
        showModal &&
        <div className={styles['container']}>
            <div className={styles['wrapper']}>
                <span onClick={() => { setShowModal(false) }} className={styles['close']}>&times;</span>
                <img alt={`kisállat-hamvasztás-${selectedImage}`} className={styles['image']} src={selectedImage}/>
            </div>
        </div>
    );
};

export default Modal;
