import React, { useContext } from 'react';

import styles from './Header.module.css';

import Context from '../Context';

const Header = (props) => {
    const { selectedMenu, setSelectedMenu } = useContext(Context);

    const elements = [
        { id: 0, path: '/hivatasunk', name: 'Hivatásunk' },
        { id: 1, path: '/szolgaltatasaink', name: 'Szolgáltatásaink' },
        { id: 2, path: '/urnak', name: 'Urnák' },
    ]

    const switchMenu = (id, path) => {
        setSelectedMenu(id);
        window.history.pushState({}, '', path);
    };

    return (
        <div className={styles['container']}>
            <ul className={styles['list']}>
                {
                    elements.map((el) => {
                        return <li key={`header-${el.id}`} onClick={() => { switchMenu(el.id, el.path); }} className={`${styles['li']} ${selectedMenu === el.id && styles['active']}`}>{el.name}</li>;
                    })
                }
            </ul>
        </div>
    );
};

export default Header;
